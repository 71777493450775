.layout {
  max-width: 500px;
  margin: auto;
}

.copyright {
  padding-top: 40px;
  padding-bottom: 30px;
  background-color: #ebeaea;
  font-family: "Montserrat";
  font-size: 14px;
}
