.accountCard {
  width: inherit;
  max-width: 500px;
  margin: 20px;
  border-radius: 15px !important;
}

.qrIcon {
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 22px;
}

.accountNumber {
  text-align: center;
  letter-spacing: 5px;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: -40px;
}

.accountName {
  text-align: center;
  margin-top: -15px;
}

.accountLogo {
  text-align: center;
  margin-top: -15px;
  height: 100px;
  width: 140px;
}

.rsvpForm {
  max-height: 1000px;
  margin-top: 20px;
}
