.scheduleCard {
  margin: 20px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  font-family: "Forum";
}

.left {
  text-align: left;
  padding-left: 20px;
  align-self: flex-end;
  font-family: "Forum";
  font-size: 18px;
}

.right {
  text-align: right;
  padding-right: 20px;
  align-self: flex-end;
  font-family: "Forum";
  font-size: 18px;
}

.scheduleTitle {
  font-size: 18px;
}

.scheduleDate {
  font-size: 22px;
  border-top: 1px solid #0f0b0b;
  border-bottom: 1px solid #0f0b0b;
  padding: 5px;
  margin: auto;
}

.reception img {
  height: 30vh;
}

.scheduleCard img {
  object-fit: contain;
}

.receptionImgBg {
  width: 100%;
  height: 30vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
  border-radius: 5px;
}
