body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  height: 100%;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
#root,
.ant-layout,
.App {
  height: 100%;
  font-size: 18px;
}

.textCenter {
  text-align: center;
}

.noMarginPadding {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

.fontMarcellus {
  font-family: "Marcellus";
}

.fontCormorant {
  font-family: "Cormorant";
  font-size: 18px;
}

.fontDancing {
  font-family: "Dancing Script";
}

.fontForum {
  font-family: "Forum";
  font-size: 18px;
}

.fontCinzel {
  font-family: "Cinzel";
  font-size: 18px;
}

.fontMontserrat {
  font-family: "Montserrat";
  font-size: 18px;
}

.fontExtraLarge {
  font-size: 60px;
}

.fontLarge {
  font-size: 36px;
}

.fontMedium {
  font-size: 24px;
}

.fontSmall {
  font-size: 18px;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
  margin-top: 50px;
}

.fullPageCover {
  height: 100%;
  padding: 50px 0px;
  /* background-image: url('images/cover.JPG'); */
  opacity: 0.9;
  background-size: cover;
  background-position: center;
  color: black;
}

.center {
  text-align: center;
  margin: auto;
}

.textBig {
  font-family: "Cormorant";
  font-size: 22px;
  padding-right: 100px;
}

.textCurly {
  font-family: "Cinzel";
  font-size: 36px;
  line-height: 1em;
  margin-top: -25px;
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.marginTopExtraLarge {
  margin-top: 100px;
}

.marginTopLarge {
  margin-top: 50px;
}

.paddingMedium {
  padding: 20px;
}

.frame {
  max-width: 500px;
  width: 100%;
  padding: 20px;
}

.radiusImage {
  border-radius: 75px;
}
.fullHeight {
  height: 100%;
}
