.countdownCard {
  width: 80px;
  margin: 6px;
  text-align: center;
  font-family: "Forum";
}
.countdown {
  font-size: 24px;
  margin: 5px;
}

.countdownSuffix {
  font-size: 14px;
  margin: 0px;
}
