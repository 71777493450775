.name {
  text-align: left;
  margin-bottom: 0;
  margin-left: 20px;
  font-size: 22px;
}

.detail {
  text-align: left;
  margin-left: 20px;
  font-size: 18px;
}
